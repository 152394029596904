import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import StorageService, { ACCESS_TOKEN } from '@/services/StorageService'
import { DataEmitterService } from '@/services/DataEmiterService'

Vue.use( VueRouter )
let isNotFacilityManager = () => StorageService.getUserRoles() !== 'FacilityManager'
let isAdvancedAdmin = () => StorageService.getUserRoles() === 'AdvancedAdmin'
let isSanityCheckAdmin = () => StorageService.getUserRoles() === 'SanityCheckAdmin'

const routes = [
  {
    path: '/form-submit',
    name: 'form-submit',
    component: () => import(/* webpackPrefetch: true */ '@/views/form/SubmitForm'),
  },
  {
    path: '/',
    redirect: {
      name: 'login',
    },
  },
  {
    path: '/users',
    name: 'users-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/users/Users'),
  },
  {
    path: '/admin-users',
    name: 'admin-users-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/users/Users'),
  },
  {
    path: '/facility-managers',
    name: 'facility-managers-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/users/Users'),
  },
  {
    path: '/juries',
    name: 'juries-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/users/Users'),
  },
  {
    path: '/advanced-admin-users-list',
    name: 'advanced-admin-users-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/users/Users'),
  },
  {
    path: '/sanity-check-admins',
    name: 'sanity-check-admins',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/users/Users'),
  },
  {
    path: '/facilities',
    name: 'facilities-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/facilities/Facilities'),
  },
  {
    path: '/facility-details/:id',
    name: 'facility-details',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/facility-details/FacilityDetails'),
  },
  {
    path: '/organizations',
    name: 'organizations-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/organizations/Organizations'),
  },
  {
    path: '/organization-details/:id',
    name: 'organization-details',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/organization-details/OrganizationDetails'),
  },
  {
    path: '/facility-details/:id/space/:spaceId',
    name: 'space',
    meta: {
      authenticated: true,
    },
    props: { floorId: null },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/facility-space-details/SpaceDetails'),
  },
  {
    path: '/user-edit/:id',
    name: 'user-edit',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/user-edit/UserEdit'),
  },
  {
    path: '/product-details/:id',
    name: 'product-details',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/product-details/ProductDetails'),
  },
  {
    path: '/service-details/:id',
    name: 'service-details',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/service-details/ServiceDetails'),
  },
  {
    path: '/products',
    name: 'products-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/products/Products'),
  },
  {
    path: '/services',
    name: 'services-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/services/Services'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'blank',
    },
    beforeEnter: ( to, from, next ) => {
      DataEmitterService.$emit( 'showLoader', true );
      let isLoggedIn = StorageService.getFromStorage( ACCESS_TOKEN );
      if ( isLoggedIn ) {
        if (StorageService.getAllUserRoles().includes('SanityCheckAdmin')) {
          next( {path: '/sanity-check'} )
        } else if (StorageService.getAllUserRoles().includes('Jury')) {
          next( {path: '/jury'} )
        } else if ( StorageService.getUserRoles() && isNotFacilityManager() === true ) {
          next( {path: '/organizations'} )
        } else {
          next( {path: '/users'} )
        }
      }
      next()
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/login/Login.vue'),
  },
  {
    path: '/bookings',
    name: 'booking-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/bookings/Bookings'),
  },
  {
    path: '/events',
    name: 'events-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/events/Events'),
  },
  {
    path: '/event-detail/:id',
    name: 'event-detail',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/event-detail/EventDetail'),
  },
  {
    path: '/texts',
    name: 'texts-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/texts/Texts'),
  },
  {
    path: '/sanity-check',
    name: 'sanity-check-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( isAdvancedAdmin() || isSanityCheckAdmin() ) {
        DataEmitterService.$emit( 'showLoader', true );
        return next();
      }
      next( { path: '/error-404' } )
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/sanity-check/SanityCheck.vue'),
  },
  {
    path: '/winner-anounce',
    name: 'winner-anounce-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/winner-anounce/WinnerAnounce.vue'),
  },
  {
    path: '/jury',
    name: 'jury-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/jury/Jury.vue'),
  },
  {
    path: '/notification-templates',
    name: 'notification-templates-list',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/notification-templates/NotificationTemplate'),
  },
  {
    path: '/text-details/:id',
    name: 'text-details',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/text-details/TextDetails'),
  },
  {
    path: '/notification-template-details/:id',
    name: 'notification-template-details',
    meta: {
      authenticated: true,
    },
    beforeEnter: ( data, path, next ) => {
      if ( StorageService.getUserRoles() && isNotFacilityManager() === false ) {
        next( { path: '/error-404' } )
      }
      DataEmitterService.$emit( 'showLoader', true );
      next();
    },
    component: () => import(/* webpackPrefetch: true */ '@/views/notification-template-details/NotificationTemplatesDetails'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import(/* webpackPrefetch: true */ '@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: {
      name: 'error-404',
    },
  },
]

const router = new VueRouter( {
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
  linkActiveClass: 'active-link',
} )
router.beforeEach( ( to, from, next ) => {
  if ( to.matched.some( record => record.meta.authenticated ) ) {
    let accessToken = StorageService.getFromStorage( ACCESS_TOKEN )

    // if(window.location.href.includes('http://')){
    //   let secureURL = window.location.href.replace('http:','https:')
    //   window.location.href = secureURL
    // }

    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if ( !store.state.authenticated && !accessToken ) {
      next( { name: 'login' } )
    } else {
      next()
    }
  } else {
    next()
  }
} )

export default router
