export const snippetList = [
  {
    name: 'City',
    key: 'city',
    value: 'City',
  },
  {
    name: 'Note',
    key: 'note',
    value: 'Note',
  },
  {
    name: 'Back',
    key: 'back',
    value: 'Back',
  },
  {
    name: 'Addresses',
    key: 'details',
    value: 'Addresses',
  },
  {
    name: 'Username',
    key: 'Username',
    value: 'Username',
  },
  {
    name: 'Address',
    key: 'address',
    value: 'Address',
  },
  {
    name: 'Lock ID',
    key: 'lockId',
    value: 'Lock ID',
  },
  {
    name: 'Contact Addresses',
    key: 'contact_addresses',
    value: 'Contact Addresses',
  },
  {
    name: 'Invalid Email',
    key: 'invalid_email',
    value: 'Invalid Email'
  },
  {
    name: 'Billing Address',
    key: 'billing_address',
    value: 'Billing Address',
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Cancel',
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Save',
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Delete Confirm message',
    key: 'delete_confirm_message',
    value: 'Are you sure you want to delete this item?',
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Delete',
  },
  {
    name: 'Edit Address',
    key: 'edit_detail',
    value: 'Edit Address',
  },
  {
    name: 'City',
    key: 'city_name',
    value: 'City',
  },
  {
    name: 'Country',
    key: 'country_name',
    value: 'Country',
  },
  {
    name: 'Company',
    key: 'company_name',
    value: 'Company',
  },
  {
    name: 'Address Type',
    key: 'addresses_type',
    value: 'Address Type',
  },
  {
    name: 'First Name',
    key: 'first_name',
    value: 'First Name',
  },
  {
    name: 'Last Name',
    key: 'last_name',
    value: 'Last Name',
  },
  {
    name: 'Phone',
    key: 'phone',
    value: 'Phone',
  },
  {
    name: 'Email',
    key: 'email',
    value: 'Email',
  },
  {
    name: 'Street Name',
    key: 'street_name',
    value: 'Street Name',
  },
  {
    name: 'Street Number',
    key: 'street_number',
    value: 'Street Number',
  },
  {
    name: 'Zip Code',
    key: 'zip',
    value: 'Zip Code',
  },
  {
    name: 'Created At',
    key: 'createDate',
    value: 'Created At',
  },
  {
    name: 'First Name',
    key: 'firstName',
    value: 'First Name',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    value: 'Last Name',
  },
  {
    name: 'Phone Number',
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    name: 'Updated At',
    key: 'updateDate',
    value: 'Updated At',
  },
  {
    name: 'Add Address',
    key: 'add_detail',
    value: 'Add Address',
  },
  {
    name: 'Incorrect Values!',
    key: 'required',
    value: 'Incorrect Values!',
  },
  {
    name: 'Name is required',
    key: 'name_is_required',
    value: 'Name is required',
  },
  {
    name: 'Surname is required',
    key: 'surname_is_required',
    value: 'Surname is required',
  },
  {
    name: 'City is required',
    key: 'city_is_required',
    value: 'City is required',
  },
  {
    name: 'Country is required',
    key: 'country_is_required',
    value: 'Country is required',
  },
  {
    name: 'Address Type is required',
    key: 'address_type_is_required',
    value: 'Address Type is required',
  },
  {
    name: 'Phone is required',
    key: 'phone_is_required',
    value: 'Phone is required',
  },
  {
    name: 'Email is required',
    key: 'email_is_required',
    value: 'Email is required',
  },
  {
    name: 'First name is required',
    key: 'firstName_is_required',
    value: 'First name is required'
  },
  {
    name: 'Last name is required',
    key: 'lastName_is_required',
    value: 'Last name is required'
  },
  {
    name: 'Phone number is required',
    key: 'phoneNumber_is_required',
    value: 'Phone number is required'
  },
  {
    name: 'Street Name is required',
    key: 'street_name_is_required',
    value: 'Street Name is required',
  },
  {
    name: 'Street Number is required',
    key: 'street_number_is_required',
    value: 'Street Number is required',
  },
  {
    name: 'Zip Code is required',
    key: 'zip_code_is_required',
    value: 'Zip Code is required',
  },
];
