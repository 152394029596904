import { snippet as UsersSnippet } from '@/snippets/users/SnipetService'
import { snippet as ContentSnippet } from '@/snippets/content/SnipetService'
import { snippet as DashboardSnippet } from '@/snippets/dashboard/SnipetService'
import { snippet as DomainsSnippet } from '@/snippets/domains/SnipetService'
import { snippet as LoginSnippet } from '@/snippets/login/SnipetService'
import { snippet as UserBarSnippet } from '@/snippets/userBar/SnipetService'
import { snippet as DetailsSnippet } from '@/snippets/details/SnipetService'
import { snippet as InstancesSnippet } from '@/snippets/instances/SnipetService'
import { snippet as BookingSnippet } from '@/snippets/bookings/SnipetService'
import { snippet as EventsSnippet } from '@/snippets/events/SnipetService'
import { snippet as SanityCheckSnippet } from '@/snippets/sanityCheck/SnipetService'

export default class SnippetsService {
  //contains current filtered snippet
  currentSnippet = []

  /**
   * returns filtered snippet {key: value}
   *
   * @param snippets
   *
   * @returns {{}}
   */
  static filterSnippet(snippets) {

    const cleanSnippet = {}
    for (let i = 0; i < snippets.length; i++) {
      cleanSnippet[snippets[i].key] = snippets[i].value
    }

    return cleanSnippet
  }


  /**
   * returns snippet by namespace and language
   *
   * @param namespace
   * @param language
   *
   * @returns {{}}
   */
  static getSnippetsByNamespaceAndLanguage(namespace, language = 'en') {

    if (!namespace || !language) {
      return
    }

    switch (namespace) {
      case 'Users':
        this.currentSnippet = UsersSnippet
        return this.filterSnippet(UsersSnippet.getSnippetByLanguage(language))
      case 'Content':
        this.currentSnippet = ContentSnippet
        return this.filterSnippet(ContentSnippet.getSnippetByLanguage(language))
      case 'Dashboard':
        this.currentSnippet = DashboardSnippet
        return this.filterSnippet(DashboardSnippet.getSnippetByLanguage(language))
      case 'Domains':
        this.currentSnippet = DomainsSnippet
        return this.filterSnippet(DomainsSnippet.getSnippetByLanguage(language))
      case 'Login':
        this.currentSnippet = LoginSnippet
        return this.filterSnippet(LoginSnippet.getSnippetByLanguage(language))
      case 'UserBar':
        this.currentSnippet = UserBarSnippet
        return this.filterSnippet(UserBarSnippet.getSnippetByLanguage(language))
      case 'Details':
        this.currentSnippet = DetailsSnippet
        return this.filterSnippet(DetailsSnippet.getSnippetByLanguage(language))
      case 'Instances':
        this.currentSnippet = InstancesSnippet
        return this.filterSnippet(InstancesSnippet.getSnippetByLanguage(language))
      case 'Bookings':
        this.currentSnippet = BookingSnippet
        return this.filterSnippet(BookingSnippet.getSnippetByLanguage(language))
      case 'Events':
        this.currentSnippet = EventsSnippet
        return this.filterSnippet(EventsSnippet.getSnippetByLanguage(language))
      case 'SanityCheck':
        this.currentSnippet = SanityCheckSnippet
        return this.filterSnippet(SanityCheckSnippet.getSnippetByLanguage(language))
    }
  }


  /**
   * returns snippet value, by snippet name and language
   *
   * @param lang
   * @param name
   *
   * @returns {string}
   */
  static filterByName(lang, name) {

    if (!this.currentSnippet || this.currentSnippet[0]?.name) {
      return
    }

    this.currentSnippet = this.currentSnippet.getSnippetByLanguage(lang)

    let snippetTranslation = ''

    for (let i = 0; i < this.currentSnippet.length; i++) {
      const snippet = this.currentSnippet[i]

      if (snippet.name === name) {
        snippetTranslation = snippet.value
        break
      }
    }

    return snippetTranslation ?? 0
  }
}

