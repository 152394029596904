export const snippetList = [
  {
    name: 'City',
    key: 'city',
    value: 'Stadt',
  },
  {
    name: 'Note',
    key: 'note',
    value: 'Notiz',
  },
  {
    name: 'Back',
    key: 'back',
    value: 'Zurück',
  },
  {
    name: 'Addresses',
    key: 'details',
    value: 'Adressen',
  },
  {
    name: 'Username',
    key: 'Username',
    value: 'Nutzername',
  },
  {
    name: 'Address',
    key: 'address',
    value: 'Die Anschrift',
  },
  {
    name: 'Lock ID',
    key: 'lockId',
    value: 'Lock ID',
  },
  {
    name: 'Contact Addresses',
    key: 'contact_addresses',
    value: 'Kontaktadressen',
  },
  {
    name: 'Invalid Email',
    key: 'invalid_email',
    value: 'Ungültige E-Mail'
  },
  {
    name: 'Billing Address',
    key: 'billing_address',
    value: 'Rechnungsadresse',
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Stornieren',
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Speichern',
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Delete Confirm message',
    key: 'delete_confirm_message',
    value: 'Möchten Sie dieses Element wirklich löschen??',
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Löschen',
  },
  {
    name: 'Edit Address',
    key: 'edit_detail',
    value: 'Adresse bearbeiten',
  },
  {
    name: 'City',
    key: 'city_name',
    value: 'Stadt',
  },
  {
    name: 'Country',
    key: 'country_name',
    value: 'Land',
  },
  {
    name: 'Company',
    key: 'company_name',
    value: 'Gesellschaft',
  },
  {
    name: 'Address type',
    key: 'addresses_type',
    value: 'Adresstyp',
  },
  {
    name: 'First name',
    key: 'first_name',
    value: 'Vorname',
  },
  {
    name: 'Last name',
    key: 'last_name',
    value: 'Familienname',
  },
  {
    name: 'Phone',
    key: 'phone',
    value: 'Telefon',
  },
  {
    name: 'Email',
    key: 'email',
    value: 'Email',
  },
  {
    name: 'Street name',
    key: 'street_name',
    value: 'Straßenname',
  },
  {
    name: 'Street number',
    key: 'street_number',
    value: 'Hausnummer',
  },
  {
    name: 'Zip code',
    key: 'zip',
    value: 'Postleitzahl',
  },
  {
    name: 'Created At',
    key: 'createDate',
    value: 'Hergestellt in',
  },
  {
    name: 'First Name',
    key: 'firstName',
    value: 'First Name',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    value: 'Last Name',
  },
  {
    name: 'Phone Number',
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    name: 'Updated At',
    key: 'updateDate',
    value: 'Aktualisiert am',
  },
  {
    name: 'Add Address',
    key: 'add_detail',
    value: 'Adresse hinzufügen',
  },
  {
    name: 'Incorrect values!',
    key: 'required',
    value: 'Falsche Werte!',
  },
];
