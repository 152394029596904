export const snippetList = [
  {
    name: 'Instances',
    key: 'instance',
    value: 'Instances',
  },
  {
    name: 'Domains',
    key: 'domains',
    value: 'Domains'
  },
  {
    name: 'Facility Details',
    key: 'facility-details',
    value: 'Facility Details',
  },
  {
    name: 'Notification Templates',
    key: 'notification-templates',
    value: 'Notification Templates',
  },
  {
    name: 'User Information',
    key: 'user-edit',
    value: 'User Information',
  },
  {
    name: 'Organization Details',
    key: 'organization-details',
    value: 'Organization Details',
  },
  {
    name: 'Product Details',
    key: 'product-details',
    value: 'Product Details',
  },
  {
    name: 'Service Details',
    key: 'service-details',
    value: 'Service Details',
  },
  {
    name: 'Booking Details',
    key: 'booking-details',
    value: 'Booking Details',
  },
  {
    name: 'Event Details',
    key: 'event-detail',
    value: 'Event Details',
  },
  {
    name: 'Text Details',
    key: 'text-details',
    value: 'Text Details',
  },
  {
    name: 'Notification Template Details',
    key: 'notification-template-details',
    value: 'Notification Template Details',
  },
  {
    name: 'Amenity',
    key: 'space',
    value: 'Amenity',
  },
  {
    name: 'Dashboard',
    key: 'dashboard',
    value: 'Dashboard'
  },
  {
    name: 'Domains List',
    key: 'domains_list',
    value: 'Domains List'
  },
  {
    name: 'Users',
    key: 'users',
    value: 'Users'
  },
  {
    name: 'Admins',
    key: 'admins',
    value: 'Admins'
  },
  {
    name: 'Admins',
    key: 'admin-users',
    value: 'Admins'
  },
  {
    name: 'Facilities',
    key: 'facilities',
    value: 'Facilities',
  },
  {
    name: 'Organizations',
    key: 'organizations',
    value: 'Organizations',
  },
  {
    name: 'Services',
    key: 'services',
    value: 'Services',
  },
  {
    name: 'Products',
    key: 'products',
    value: 'Products',
  },
  {
    name: 'Products',
    key: 'products',
    value: 'Products',
  },
  {
    name: 'Services',
    key: 'services',
    value: 'Services',
  },
  {
    name: 'Bookings',
    key: 'bookings',
    value: 'Bookings',
  },
  {
    name: 'Events',
    key: 'events',
    value: 'Events',
  },
  {
    name: 'Application Texts',
    key: 'texts',
    value: 'Application Texts',
  },
  {
    name: 'Notification Templates',
    key: 'notificationTemplates',
    value: 'Notification Templates',
  },
  {
    name: 'Sanity Check',
    key: 'sanityCheck',
    value: 'Sanity Check',
  },
  {
    name: 'Jury',
    key: 'jury',
    value: 'Jury',
  },
  {
    name: 'Winner announce',
    key: 'winnerAnnounce',
    value: 'Winner announce',
  },
];
