export const snippetList = [
  {
    name: 'Events',
    key: 'events',
    value: 'Events',
  },
  {
    name: 'Something went wrong',
    key: 'delete_error_message',
    value: 'Something went wrong',
  },
  {
    name: 'OK',
    key: 'ok',
    value: 'OK',
  },
  {
    name: 'Add event',
    key: 'add_event',
    value: 'Add event',
  },
  {
    name: 'Edit event',
    key: 'edit_events',
    value: 'Edit event',
  },
  {
    name: 'Search',
    key: 'search',
    value: 'Search ',
  },
  {
    name: 'Id',
    key: 'id',
    value: 'Id ',
  },
  {
    name: 'Name',
    key: 'name',
    value: 'Name ',
  },
  {
    name: 'Description',
    key: 'description',
    value: 'Description',
  },
  {
    name: ' Public',
    key: 'isPublic',
    value: 'Public',
  },
  {
    name: 'Booking  Date',
    key: 'bookingDate',
    value: 'Booking  Date',
  },
  {
    name: 'User Name',
    key: 'userName',
    value: 'User Name ',
  },
  {
    name: 'Start Time',
    key: 'startTime',
    value: 'Start Time',
  },
  {
    name: 'End Time',
    key: 'endTime',
    value: 'End Time',
  },
  {
    name: 'Facility Name',
    key: 'facilityName',
    value: 'Facility Name ',
  },
  {
    name: 'Amenity Name',
    key: 'spaceName',
    value: 'Amenity Name',
  },
  {
    name: 'Organization',
    key: 'organization',
    value: 'Organization ',
  },
  {
    name: 'Credit cost',
    key: 'creditsCost',
    value: 'Credit cost ',
  },
  {
    name: 'Actions',
    key: 'actions',
    value: 'Actions ',
  },
  {
    name: 'Status',
    key: 'status',
    value: 'Status ',
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Cancel ',
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Delete ',
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Save ',
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Required',
    key: 'required',
    value: 'Required ',
  },
  {
    name: 'Are you sure you want to delete event?',
    key: 'delete_confirm_message',
    value: 'Are you sure you want to delete event? ',
  },
  {
    name: 'Please enter event name.',
    key: 'label_name',
    value: 'Please enter event name',
  },
  {
    name: 'Example new event',
    key: 'name_placeholder',
    value: 'Example new event',
  },
  {
    name: 'Please enter event description.',
    key: 'label_description',
    value: 'Please enter event description',
  },
  {
    name: 'Example description',
    key: 'description_placeholder',
    value: 'Example description',
  },
  {
    name: 'Special time request',
    key: 'special_time_request',
    value: 'Special time request',
  },
  {
    name: 'Example special time request',
    key: 'example_special_time_request',
    value: 'Special time request description',
  },
  {
    name: 'The event  details',
    key: 'events_details',
    value: 'The event  details',
  },
  {
    name: 'Are you sure you want to delete image?',
    key: 'delete_phote_confirm_message',
    value: 'Are you sure you want to delete image?',
  },
  {
    name: 'The Event amenity services',
    key: 'event_space_services',
    value: 'The Event amenity services',
  },
  {
    name: 'Event special request',
    key: 'special_request',
    value: 'Event special request',
  },
  {
    name: 'The Event participants',
    key: 'event_participants',
    value: 'The Event participants',
  },
  {
    name: 'Participant Email',
    key: 'participant_email',
    value: 'Participant Email',
  },
  {
    name: 'Add new participant with email',
    key: 'add_participant_with_email',
    value: 'Add new participant with email',
  },

];
