import ApiRequestService from '@/services/ApiRequestService'
import StorageService, {
  ACCESS_TOKEN, ALL_ROLES,
  EMAIL,
  REFRESH_TOKEN,
  TOKEN_TYPE, USER_ID, USER_ROLES,
} from '@/services/StorageService'
import * as jwt from "jsonwebtoken";

export default class AuthenticationService extends ApiRequestService {
  static refreshedCount = 0;
  static refreshTimeout = 0;
  static signIn(data) {
    return new Promise(resolve => {
      this.postRequest('api/auth/admin-login', {
        email: data.email,
        password: data.password,
      }, {}, true).then(responseData => {
        if (responseData?.access_token) {
          const {roles} = jwt.decode( responseData?.access_token );
          if(!roles || !(roles.includes('Admin') || !roles.includes('CompanyManager') || !roles.includes('FacilityManager'))){
            resolve(false)
            return;
          }

          StorageService.setToStorage(ACCESS_TOKEN, responseData.access_token);
          StorageService.setToStorage(REFRESH_TOKEN, responseData.refresh_token);
          StorageService.setToStorage(USER_ID, responseData.user_id);
          StorageService.setToStorage(ALL_ROLES, JSON.stringify(roles));
          StorageService.setToStorage(USER_ROLES, roles[0]);
          StorageService.setToStorage(TOKEN_TYPE, 'Bearer');

          return resolve(true)
        }else{
          return resolve(false)
        }
      })
    })
  }
  static refresh(refreshToken) {
    return new Promise(resolve => {
      this.refreshTimeout = setTimeout(()=>{
        if(this.refreshedCount === 5){
          this.logout();
          return;
        }
        this.refreshedCount++;
        let userId = StorageService.getFromStorage( USER_ID );
        resolve(this.postRequest('api/auth/refresh', {
          "user_id": userId,
        }, {refreshToken}).then(responseData => {
          if (responseData?.access_token) {
            StorageService.setToStorage(ACCESS_TOKEN, responseData.access_token);
            StorageService.setToStorage(REFRESH_TOKEN, responseData.refresh_token);
            StorageService.setToStorage(USER_ID, responseData.user_id);
            StorageService.setToStorage(TOKEN_TYPE, 'Bearer');

            return true
          }
        }))
      },300);
    })
  }

  static get email() {
    return StorageService.getFromStorage(EMAIL)
  }

  static isAuthenticated() {
    return !!StorageService.getFromStorage(ACCESS_TOKEN)
  }
}
