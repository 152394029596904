import { config } from '@/config/config'
import StorageService, {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_TYPE
} from '@/services/StorageService'
import AuthenticationService from "@/services/AuthenticationService";
import { DataEmitterService } from "@/services/DataEmiterService";

export default class ApiRequestService {
  static axios = require( 'axios' ).default

  token_type = 'Bearer'
  access_token = ''

  static getTokenData() {
    try {
      if ( !this.access_token ) {
        let tokenType = StorageService.getFromStorage( TOKEN_TYPE )
        let refreshToken = StorageService.getFromStorage( REFRESH_TOKEN )
        let accessToken = StorageService.getFromStorage( ACCESS_TOKEN )

        return {
          tokenType,
          refreshToken,
          accessToken,
        }
      }

      return {
        tokenType: this.token_type,
        accessToken: this.access_token,
      }
    } catch ( e ) {
      console.log( e )
      return e
    }
  }

  static getRequest( url ) {
    try {
      const { tokenType, accessToken, refreshToken } = this.getTokenData()

      const headers = {
        Authorization: `${ tokenType } ${ accessToken }`,
      }
      DataEmitterService.$emit( 'showLoader', true );
      return ApiRequestService.axios.get( config.API_URL + url, { headers } )
        .then( response => {
          DataEmitterService.$emit( 'showLoader', false );
          return response.data
        } )
        .catch( ( error ) => {
          if ( error ) {
            DataEmitterService.$emit( 'showLoader', false );

            let key = error.toString().includes( '401' );
            if ( key ) {
              return AuthenticationService.refresh( refreshToken ).then( ( ok ) => {
                if ( ok ) {
                  return this.getRequest( url );
                }

                this.logout();
              } ).catch( ( error ) => {
                if ( error ) {
                  DataEmitterService.$emit( 'showLoader', false );
                  let key = error.toString().includes( '401' );
                  if ( key ) {
                    this.logout()
                  }
                }
              } );
            } else {

              return this.catchErrors(error);
            }
          }
        } )
    } catch ( e ) {
      console.log( e )
      return e
    }
  }

  static postRequest( url, data, header, isLogin = false ) {
    try {
      const { tokenType, accessToken, refreshToken } = this.getTokenData();

      const headers = {
        Authorization: `${ tokenType } ${ header?.refreshToken ?? accessToken }`,
        'Access-Control-Allow-Origin': '*'
      }
      DataEmitterService.$emit( 'showLoader', true );
      return ApiRequestService.axios.post( config.API_URL + url, data, { headers } )
        .then( response => {
          DataEmitterService.$emit( 'showLoader', false );
          return response.data
        } )
        .catch( error => {
          if ( error ) {
            DataEmitterService.$emit( 'showLoader', false );
            let key = error.toString().includes( '401' );

            if ( key && !isLogin ) {
              return AuthenticationService.refresh( refreshToken ).then( ( ok ) => {
                if ( ok ) {
                  return this.postRequest( url, data );
                }

                this.logout();
              } ).catch( ( error ) => {
                if ( error ) {
                  DataEmitterService.$emit( 'showLoader', false );
                  let key = error.toString().includes( '401' );
                  if ( key ) {
                    this.logout()
                  }
                  return error;
                }
              } );
            }

            return this.catchErrors(error);
          }
          console.log( error )
        } )
    } catch ( e ) {
      console.log( e )
      return e
    }
  }

  static updateRequest( url, data ) {
    try {
      const { tokenType, accessToken, refreshToken } = this.getTokenData()

      const headers = {
        Authorization: `${ tokenType } ${ accessToken }`,
      }
      DataEmitterService.$emit( 'showLoader', true );
      return ApiRequestService.axios.patch( config.API_URL + url, data, { headers } )
        .then( response => {
          DataEmitterService.$emit( 'showLoader', false );
          DataEmitterService.$emit( 'showSuccess', true );
          return response.data
        } )
        .catch( error => {
          if ( error ) {
            DataEmitterService.$emit( 'showLoader', false );
            let key = error.toString().includes( '401' );

            if ( key ) {
              return AuthenticationService.refresh( refreshToken ).then( ( ok ) => {
                if ( ok ) {
                  return this.updateRequest( url, data );
                }

                this.logout();
              } ).catch( ( error ) => {
                if ( error ) {
                  DataEmitterService.$emit( 'showLoader', false );
                  let key = error.toString().includes( '401' );
                  if ( key ) {
                    this.logout()
                  }
                }
              } );
            }

            return this.catchErrors(error);
          }
        } )
    } catch ( e ) {
      console.log( e )
      return e
    }
  }
  static patchRequest( url, data ) {

    try {
      const { tokenType, accessToken, refreshToken } = this.getTokenData()

      const headers = {
        Authorization: `${ tokenType } ${ accessToken }`,
      }

      DataEmitterService.$emit( 'showLoader', true );
      return ApiRequestService.axios.patch( config.API_URL + url, data, { headers } )
        .then( response => {
          DataEmitterService.$emit( 'showLoader', false );
          return response.data
        } )
        .catch( error => {
          if ( error ) {
            DataEmitterService.$emit( 'showLoader', false );
            let key = error.toString().includes( '401' );

            if ( key ) {
              return AuthenticationService.refresh( refreshToken ).then( ( ok ) => {
                if ( ok ) {
                  return this.patchRequest( url, data );
                }

                this.logout();
              } ).catch( ( error ) => {
                if ( error ) {
                  DataEmitterService.$emit( 'showLoader', false );
                  let key = error.toString().includes( '401' );
                  if ( key ) {
                    this.logout()
                  }
                }
              } );
            }

            return this.catchErrors(error);
          }
        } )
    } catch ( e ) {
      console.log( e )
      return e
    }
  }

  static deleteRequest( url ) {
    try {
      const { tokenType, accessToken, refreshToken } = this.getTokenData()

      const headers = {
        Authorization: `${ tokenType } ${ accessToken }`,
      }

      DataEmitterService.$emit( 'showLoader', true );
      return ApiRequestService.axios.delete( config.API_URL + url, { headers } )
        .then( response => {
          DataEmitterService.$emit( 'showSuccess', true );
          DataEmitterService.$emit( 'showLoader', false );
          return response
        } )
        .catch( error => {
          if ( error ) {
            localStorage.setItem('error', JSON.stringify(error))
            DataEmitterService.$emit( 'showLoader', false );
            let key = error.toString().includes( '401' );

            if ( key ) {
              return AuthenticationService.refresh( refreshToken ).then( ( ok ) => {
                if ( ok ) {
                  return this.deleteRequest( url );
                }
                this.logout();
              } ).catch( ( error ) => {
                if ( error ) {
                  DataEmitterService.$emit( 'showLoader', false );
                  let key = error.toString().includes( '401' );
                  if ( key ) {
                    this.logout();
                  }
                }
              } );
            }

            return this.catchErrors(error);
          }
        } )
    } catch ( e ) {
      console.log( e )
      return e
    }
  }

  static logout() {
    DataEmitterService.$emit( 'showLoader', false );
    StorageService.clearAll()
    window.location.pathname = '/login';
  }


  static catchErrors( error ) {
    DataEmitterService.$emit( 'showError', getValueFromArray(error?.response?.data?.errors?.map(error => Object.values(error)?.map(values => Object.keys(values)?.length ? Object.values(values)?.map(item => item + '<br>') : values + '<br>'))) );

    return {
      key: error?.response?.data?.error?.toString(),
      defaultMessage: error?.response?.data?.message?.toString() ?? error.toString(),
      errorMessage: Array.isArray(error?.response?.data?.errorMessage) ? error?.response?.data?.errorMessage?.map(error => error + '<br>') : error?.response?.data?.errorMessage,
      errors: getValueFromArray(error?.response?.data?.errors?.map(error => Object.values(error)?.map(values => Object.keys(values)?.length ? Object.values(values)?.map(item => item + '<br>') : values + '<br>'))),
    }
  }
}

function getValueFromArray(array){
  if(!Array.isArray(array)){
    return array;
  }
  return getValueFromArray(array[0])
}
