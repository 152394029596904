import Vue from 'vue'
import Vuex from 'vuex'
import StorageService, { ACCESS_TOKEN, EMAIL, TOKEN_TYPE } from '@/services/StorageService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authenticatedToken: false,
  },
  mutations: {
    setAuthentication(state, status) {
      state.authenticatedToken = status
    },
    getAuthentication() {
      return this.authenticatedToken
    },
  },
  getters: {},
  actions: {},
  modules: {},
})
