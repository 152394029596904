export const snippetList = [
  {
    name: 'State',
    key: 'state',
    value: 'Zustand',
  },
  {
    name: 'URL',
    key: 'url',
    value: 'URL',
  },
  {
    name: 'URL is not correct!',
    key: 'incorrect_url',
    value: 'URL ist nicht korrekt!',
  },
  {
    name: 'URL is required!',
    key: 'url_is_required',
    value: 'URL ist erforderlich!',
  },
  {
    name: 'URL is not valid!',
    key: 'url_is_not_valid',
    value: 'URL is not valid!',
  },
  {
    name: 'Back',
    key: 'back',
    value: 'Zurück',
  },
  {
    name: 'Edit configuration',
    key: 'edit_configuration',
    value: 'Konfiguration bearbeiten',
  },
  {
    name: 'Value',
    key: 'value',
    value: 'Wert',
  },
  {
    name: 'Value is required!',
    key: 'value_is_required',
    value: 'Wert ist erforderlich!',
  },
  {
    name: 'Instances',
    key: 'instances',
    value: 'Instanzen',
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Stornieren',
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Speichern',
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Health',
    key: 'health',
    value: 'Gesundheit',
  },
  {
    name: 'Delete Confirm message',
    key: 'delete_confirm_message',
    value: 'Möchten Sie dieses Element wirklich löschen??',
  },
  {
    name: 'Activate Confirm message',
    key: 'activate_confirm_message',
    value: 'Möchten Sie die echte Instanz wirklich deaktivieren?',
  },
  {
    name: 'Deactivate Confirm message',
    key: 'deactivate_confirm_message',
    value: 'Möchten Sie diese Instanz wirklich aktivieren?',
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Löschen',
  },
  {
    name: 'Activate',
    key: 'activate',
    value: 'aktivieren Sie',
  },
  {
    name: 'Deactivate',
    key: 'deactivate',
    value: 'Deaktivieren',
  },
  {
    name: 'Edit Address',
    key: 'edit_detail',
    value: 'Adresse bearbeiten',
  },
  {
    name: 'Search',
    key: 'search',
    value: 'Suche',
  },
  {
    name: 'Name',
    key: 'name',
    value: 'Name',
  },
  {
    name: 'Active',
    key: 'active',
    value: 'Active',
  },
  {
    name: 'User',
    key: 'user',
    value: 'Kundin/Kunde',
  },
  {
    name: 'Key',
    key: 'key',
    value: 'Taste',
  },
  {
    name: 'Id',
    key: 'id',
    value: 'Ausweis',
  },
  {
    name: 'Actions',
    key: 'actions',
    value: 'Aktionen',
  },
  {
    name: 'Domain',
    key: 'domain',
    value: 'Domain',
  },
  {
    name: 'Server',
    key: 'server',
    value: 'Server',
  },
  {
    name: 'Type',
    key: 'type',
    value: 'Art',
  },
  {
    name: 'Created At',
    key: 'createDate',
    value: 'Hergestellt in',
  },
  {
    name: 'First Name',
    key: 'firstName',
    value: 'First Name',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    value: 'Last Name',
  },
  {
    name: 'Phone Number',
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    name: 'Updated At',
    key: 'updateDate',
    value: 'Aktualisiert am',
  },
  {
    name: 'The key has already been taken.',
    key: 'add_instance_error_message',
    value: 'Der Schlüssel wurde bereits genommen.'
  },
  {
    name: 'Add Address',
    key: 'add_detail',
    value: 'Adresse hinzufügen',
  },
  {
    name: 'Add Instance',
    key: 'add_instance',
    value: 'Instanz hinzufügen',
  },
  {
    name: 'Edit Instance',
    key: 'edit_instance',
    value: 'Instanz bearbeiten',
  },
  {
    name: 'Incorrect values!',
    key: 'required',
    value: 'Falsche Werte!',
  },
  {
    name: 'Domain is required',
    key: 'domain_is_required',
    value: 'Domäne ist erforderlich',
  },
  {
    name: 'Server is required',
    key: 'server_is_required',
    value: 'Server ist erforderlich',
  },
  {
    name: 'User is required',
    key: 'user_is_required',
    value: 'Kunde ist erforderlich',
  },
  {
    name: 'Instance Type is required',
    key: 'instance_type_is_required',
    value: 'Instanztyp ist erforderlich',
  },
  {
    name: 'Name is required!',
    key: 'name_is_required',
    value: 'Name ist erforderlich!',
  },
  {
    name: 'Key is required!',
    key: 'key_is_required',
    value: 'Schlüssel ist erforderlich!',
  },
  {
    name: 'Configuration',
    key: 'configuration',
    value: 'Konfiguration',
  },
];
