export const snippetList = [
  {
    name: 'Domains',
    key: 'domains',
    value: 'Domains'
  },
  {
    name: 'AddDomain',
    key: 'add_domain',
    value: 'Add Domain'
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Cancel'
  },
  {
    name: 'Domain has been used!',
    key: 'cant_remove_domain',
    value: 'Domain has been used!'
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Save'
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Delete Confirm message',
    key: 'delete_confirm_message',
    value: 'Are you sure you want to delete this item?'
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Delete'
  },
  {
    name: 'Edit Domain',
    key: 'edit_domain',
    value: 'Edit Domain'
  },
  {
    name: 'URL is not correct!',
    key: 'incorrect_url',
    value: 'URL is not correct!'
  },
  {
    name: 'Key is required!',
    key: 'key_is_required',
    value: 'Key is required!'
  },
  {
    name: 'Name is required!',
    key: 'name_is_required',
    value: 'Name is required!'
  },
  {
    name: 'Description is required!',
    key: 'description_is_required',
    value: 'Description is required!'
  },
  {
    name: 'Short Description is required!',
    key: 'shortDescription_is_required',
    value: 'Short Description is required!'
  },
  {
    name: 'URL is required!',
    key: 'url_is_required',
    value: 'URL is required!'
  },
  {
    name: 'URL is not valid!',
    key: 'url_is_not_valid',
    value: 'URL is not valid!',
  },
  {
    name: 'Created At',
    key: 'createDate',
    value: 'Created At'
  },
  {
    name: 'First Name',
    key: 'firstName',
    value: 'First Name',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    value: 'Last Name',
  },
  {
    name: 'Phone Number',
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    name: 'Updated At',
    key: 'updateDate',
    value: 'Updated At'
  },
  {
    name: 'URL',
    key: 'url',
    value: 'URL'
  },
  {
    name: 'Key',
    key: 'key',
    value: 'Key'
  },
  {
    name: 'Id',
    key: 'id',
    value: 'Id'
  },
  {
    name: 'Actions',
    key: 'actions',
    value: 'Actions'
  },
  {
    name: 'Domains List',
    key: 'domains_list',
    value: 'Domains List'
  },
  {
    name: 'The key has already been taken.',
    key: 'add_domain_error_message',
    value: 'The key has already been taken.'
  },
  {
    name: 'Search',
    key: 'search',
    value: 'Search'
  },
];
