//
//
//
//
//
//

import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/content/Content.vue'
import LayoutRaw from '@/layouts/Raw.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutRaw,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) {
        return null
      }

      // ---------- if we don`t need header ----------
      //
      // if (route.value.meta.layout === 'blank') {
      //   return 'layout-blank'
      // }

      if (route.value.name === 'form-submit') {
        return 'layout-raw'
      }

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
