export const snippetList = [
  {
    name: 'Logout',
    key: 'logout',
    value: 'Logout'
  },
  {
    name: 'Users',
    key: 'users',
    value: 'Users',
  },
  {
    name: 'Admins',
    key: 'admins',
    value: 'Admins'
  },
  {
    name: 'Facilities',
    key: 'facilities',
    value: 'Facilities',
  },
  {
    name: 'Organizations',
    key: 'organizations',
    value: 'Organizations',
  },
  {
    name: 'Services',
    key: 'services',
    value: 'Services',
  },
  {
    name: 'Products',
    key: 'products',
    value: 'Products',
  },
  {
    name: 'Domains List',
    key: 'domains_list',
    value: 'Domains List',
  },
  {
    name: 'Dashboard',
    key: 'dashboard',
    value: 'Dashboard'
  },
  {
    name: 'Sanity Check',
    key: 'sanityCheck',
    value: 'Sanity Check',
  },
  {
    name: 'Jury',
    key: 'jury',
    value: 'Jury',
  },
  {
    name: 'Winner announce',
    key: 'winnerAnnounce',
    value: 'Winner announce',
  },
];
