export const snippetList = [
  {
    name: 'Login',
    key: 'login',
    value: 'Login'
  },
  {
    name: 'Invalid email or password',
    key: 'login_invalid_data',
    value: 'Invalid email or password'
  },
  {
    name: 'Invalid Email',
    key: 'invalid_email',
    value: 'Invalid Email'
  },
  {
    name: 'Email is required',
    key: 'email_is_required',
    value: 'Email is required'
  },
  {
    name: 'First name is required',
    key: 'firstName_is_required',
    value: 'First name is required'
  },
  {
    name: 'Last name is required',
    key: 'lastName_is_required',
    value: 'Last name is required'
  },
  {
    name: 'Phone number is required',
    key: 'phoneNumber_is_required',
    value: 'Phone number is required'
  },
  {
    name: 'Password is required',
    key: 'password_is_required',
    value: 'Password is required'
  },
  {
    name: 'Language is required',
    key: 'language_is_required',
    value: 'Language is required'
  },
];
