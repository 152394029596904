//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AuthenticationService from "@/services/AuthenticationService";

import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import SnippetsService from '@/services/SnippetsService'
import StorageService, {
  ACCESS_TOKEN,
  EMAIL,
  LANGUAGE_KEY,
  TOKEN_TYPE,
  USER_ID,
  USER_NAME, USER_PHOTO,
} from '@/services/StorageService'
import { DataEmitterService } from "@/services/DataEmiterService";

export default {
  mounted() {
    const userId = StorageService.getFromStorage(USER_ID);

    AuthenticationService.getRequest( `api/admin-users/${ userId }` ).then(response => {
      this.userName = `${response.firstName} ${response.lastName}`;
      this.userPhoto = response.photo?.url ?? null;
      StorageService.saveUserName(USER_NAME, this.userName);
      StorageService.setToStorage(USER_PHOTO, response.photo?.url ?? '');
      this.isAdvancedAdmin = StorageService.getAllUserRoles().includes('AdvancedAdmin');
      this.isJury = StorageService.getAllUserRoles().includes('Jury');
      this.isSanityCheckAdmin = StorageService.getAllUserRoles().includes('SanityCheckAdmin');
    });
  },

  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage('UserBar', StorageService.getFromStorage(LANGUAGE_KEY))

    return {
      isAdvancedAdmin: false,
      isJury: false,
      isSanityCheckAdmin: false,
      userSnippet: snippets ?? {},
      email: StorageService.getFromStorage(EMAIL),
      userName: '',
      userPhoto: null,
    }
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods: {

    logout() {
      StorageService.clearAll()

      setTimeout(() => {
        this.$router.push('/login')
      }, 100)
    },
  },
}
