import {snippetList as DeSnippetList} from './de/snippet';
import {snippetList as EnSnippetList} from './en/snippet';

export const snippet = {
  getSnippetByLanguage(language){
    switch (language) {
      case 'en':
        return EnSnippetList;
      case 'de':
        return DeSnippetList;
    }
  },

  getSnippets(){
    return DeSnippetList.concat(EnSnippetList);
  }
}
