export const snippetList = [
  {
    name: 'Bookings',
    key: 'bookings',
    value: 'Bookings',
  },
  {
    name: 'Add Booking',
    key: 'add_booking',
    value: 'Add Booking ',
  },
  {
    name: 'Edit Booking',
    key: 'edit_booking',
    value: 'Edit Booking',
  },
  {
    name: 'Search',
    key: 'search',
    value: 'Search ',
  },
  {
    name: 'Uuid',
    key: 'uuid',
    value: 'Uuid ',
  },
  {
    name: 'Organization',
    key: 'organization',
    value: 'Organization ',
  },
  {
    name: 'Credit cost',
    key: 'creditsCost',
    value: 'Credit cost ',
  },
  {
    name: 'Facility Name',
    key: 'facilityName',
    value: 'Facility Name ',
  },
  {
    name: 'Amenity Name',
    key: 'spaceName',
    value: 'Amenity Name',
  },
  {
    name: 'Booking Date',
    key: 'bookingDate',
    value: 'Booking Date ',
  },
  {
    name: 'User Name',
    key: 'userName',
    value: 'User Name ',
  },
  {
    name: 'Start Time',
    key: 'startTime',
    value: 'Start Time',
  },
  {
    name: 'End Time',
    key: 'endTime',
    value: 'End Time',
  },
  {
    name: 'Actions',
    key: 'actions',
    value: 'Actions ',
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Cancel ',
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Delete ',
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Save ',
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Required',
    key: 'required',
    value: 'Required ',
  },
  {
    name: 'Are you sure you want to delete booking?',
    key: 'delete_confirm_message',
    value: 'Are you sure you want to delete booking? ',
  },

];
