export const snippetList = [

  {
    name: 'Domains',
    key: 'domains',
    value: 'Domänen'
  },
  {
    name: 'AddDomain',
    key: 'add_domain',
    value: 'Domäne hinzufügen'
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Stornieren'
  },
  {
    name: 'Domain has been used!',
    key: 'cant_remove_domain',
    value: 'Domain wurde verwendet!'
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Speichern'
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Delete Confirm message',
    key: 'delete_confirm_message',
    value: 'Möchten Sie dieses Element wirklich löschen?'
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Löschen'
  },
  {
    name: 'Edit Domain',
    key: 'edit_domain',
    value: 'Domäne bearbeiten'
  },
  {
    name: 'URL is not correct!',
    key: 'incorrect_url',
    value: 'URL ist nicht korrekt!'
  },
  {
    name: 'Key is required!',
    key: 'key_is_required',
    value: 'Schlüssel ist erforderlich!'
  },
  {
    name: 'Name is required!',
    key: 'name_is_required',
    value: 'Name ist erforderlich!'
  },
  {
    name: 'URL is required!',
    key: 'url_is_required',
    value: 'URL ist erforderlich!'
  },
  {
    name: 'URL is not valid!',
    key: 'url_is_not_valid',
    value: 'URL is not valid!',
  },
  {
    name: 'Created At',
    key: 'createDate',
    value: 'Hergestellt in'
  },
  {
    name: 'First Name',
    key: 'firstName',
    value: 'First Name',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    value: 'Last Name',
  },
  {
    name: 'Phone Number',
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    name: 'Updated At',
    key: 'updateDate',
    value: 'Aktualisiert am'
  },
  {
    name: 'URL',
    key: 'url',
    value: 'URL'
  },
  {
    name: 'Key',
    key: 'key',
    value: 'Taste'
  },
  {
    name: 'Id',
    key: 'id',
    value: 'Ausweis'
  },
  {
    name: 'Actions',
    key: 'actions',
    value: 'Aktionen'
  },
  {
    name: 'Domains List',
    key: 'domains_list',
    value: 'Domänenliste'
  },
  {
    name: 'The key has already been taken.',
    key: 'add_domain_error_message',
    value: 'Der Schlüssel wurde bereits genommen.'
  },
  {
    name: 'Search',
    key: 'search',
    value: 'Suche'
  },
];
