
import { computed, ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import SnippetsService from '@/services/SnippetsService'
import ThemeSwitcher from '../components/ThemeSwitcher.vue'
import AppBarUserMenu from '../components/AppBarUserMenu.vue'
import StorageService, { EMAIL, LANGUAGE_KEY } from '@/services/StorageService'
import { DataEmitterService } from '@/services/DataEmiterService'
import AuthenticationService from '@/services/AuthenticationService'

export default {
  components: {
    ThemeSwitcher,
    AppBarUserMenu,
  },

  setup() {
    const isDrawerOpen = ref( null )
    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Content', StorageService.getFromStorage( LANGUAGE_KEY ) || 'en' )

    /**
     * get language form language select box ( Login.vue )
     */
    DataEmitterService.$off( 'language' )
    DataEmitterService.$on( 'language', lang => {
      snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Content', lang )
      this.contentSnippet = snippets
    } )
    let loader;
    let success;
    let error;
    DataEmitterService.$on( 'showLoader', data => {
      clearTimeout( loader );
      loader = setTimeout( () => {
        this.showLoader = data;
      }, 1000 )
    } )
    DataEmitterService.$on( 'showSuccess', data => {
      clearTimeout( success );
      clearTimeout( error );
      this.showErrorText = ''
      this.showError = false;
      success = setTimeout( () => {
        this.showSuccess = data;
      }, 1000 )
    } )
    DataEmitterService.$on( 'showError', data => {
      clearTimeout( error );
      clearTimeout( success );
      this.showSuccess = false
      error = setTimeout( () => {
        this.showError = true;
        this.showErrorText = data;
      }, 500 )
    } )
    return {
      isAdvancedAdmin: false,
      isJury: false,
      isSanityCheckAdmin: false,
      breadcrumb: [],
      contentSnippet: snippets ?? {},
      isAuthenticated: AuthenticationService.isAuthenticated(),
      isManager: false,
      isAllowed: true,
      showLoader: false,
      showSuccess: false,
      userMenuIsActive: false,
      showErrorText: '',
      showError: false,
      userTypes: [
        {
          routeName: 'sanity-check-admins',
          name: 'Sanity Check Admins'
        },
        {
          routeName: 'admin-users-list',
          name: 'Admins'
        },
        {
          routeName: 'advanced-admin-users-list',
          name: 'Advanced Admins'
        },
        {
          routeName: 'facility-managers-list',
          name: 'Facility Managers'
        },
        {
          routeName: 'juries-list',
          name: 'Juries'
        },
        {
          routeName: 'users-list',
          name: 'Users'
        }
      ],
      isAdmin: false,
      isActiveUsers: this.$route.path
    }
  },

  beforeMount() {
    DataEmitterService.$on( 'route-change', () => {
      this.computeBreadcrumb()
    } )
    DataEmitterService.$on( 'show-correct-access', () => {
      this.getCorrectAccess();
    } )

    this.getCorrectAccess();

    document.addEventListener('click', () => {
      if(this.userMenuIsActive){
        this.userMenuIsActive = false
      }
    })
  },

  methods: {

    closeErrorDialog(){
      this.showErrorText = null;
      this.showError = false;
    },

    computeBreadcrumb() {
      const url = this.$route.path.split( '/' )
      const storageUserName = this.$route.path.includes( '/user-edit' ) ? StorageService.getUserName() : '';
      const storageFacility = this.$route.path.includes( '/facility-details' ) ? StorageService.getFacilityFromStorage() : '';
      const storageFacilitySpace = this.$route.path.includes( '/space' ) ? StorageService.getFacilitySpaceFromStorage() : '';
      const storageOrganization = this.$route.path.includes( '/organization' ) ? StorageService.getOrganizationFromStorage() : '';
      const storageProductDetails = this.$route.path.includes( '/product-details' ) ? StorageService.getProductFromStorage() : '';
      const storageServiceDetails = this.$route.path.includes( '/service-details' ) ? StorageService.getFavourFromStorage() : '';
      const storageBookingDetails = this.$route.path.includes( '/booking-details' ) ? StorageService.getBookingToStorage() : '';
      const storageEventDetails = this.$route.path.includes( '/event-detail' ) ? StorageService.getEventToStorage() : '';
      const storageTextsDetails = this.$route.path.includes( '/text-details' ) ? StorageService.getTextsToStorage() : '';
      const storageNotificationTemplateDetails = this.$route.path.includes( '/notification-template-details' ) ? StorageService.getNotificationTemplateToStorage() : '';
      let translatedUrl = 'Design Tech'
      const urlFragments = [ { exact: true, text: 'Design Tech', to: '/' } ];

      for ( let i = 0; i < url.length; i++ ) {
        if ( url[i] ) {
          let onlyFirstUppercase = url[i].charAt( 0 ).toUpperCase() + url[i].slice( 1 );
          if ( storageFacility ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageFacility.facility.name : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = '/facilities';
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }

            if ( storageFacilitySpace ) {
              onlyFirstUppercase = url[i] === this.$route.params.spaceId ? storageFacilitySpace.name : '';
              if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
                translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
                urlFragments[urlFragments.length - 2].to = `/facility-details/${ storageFacility.facility.id }`;
                urlFragments[urlFragments.length - 1].to = `/facility-details/${ storageFacility.facility.id }`;
                urlFragments.push( {
                  exact: true,
                  text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                  to: ''
                } )
              }
            }
          }

          if ( storageOrganization ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageOrganization.name : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/organizations`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageProductDetails ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageProductDetails.name : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/products`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageServiceDetails ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageServiceDetails.name : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/services`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageBookingDetails ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageBookingDetails.name : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/bookings`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageEventDetails ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageEventDetails.name : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/events`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageTextsDetails ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageTextsDetails.key : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/texts`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageNotificationTemplateDetails ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageNotificationTemplateDetails.key : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/notification-templates`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( storageUserName ) {
            onlyFirstUppercase = url[i] === this.$route.params.id ? storageUserName : '';
            if ( !translatedUrl.includes( onlyFirstUppercase ) ) {
              translatedUrl += ` / ${ this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase }`
              urlFragments[urlFragments.length - 1].to = `/${ sessionStorage.getItem( 'last-opened-user-type' ) ?? 'users' }`;
              urlFragments.push( {
                exact: true,
                text: this.contentSnippet[url[i]] ? this.contentSnippet[url[i]] : onlyFirstUppercase,
                to: ''
              } )
            }
          }

          if ( this.contentSnippet[url[i]] ) {
            translatedUrl += ` / ${ this.contentSnippet[url[i]] }`
            urlFragments.push( { exact: true, text: this.contentSnippet[url[i]], to: '' } )
          }
        }
      }

      this.breadcrumb = urlFragments;
    },

    getCorrectAccess() {
      this.isManager = StorageService.getUserRoles() === 'CompanyManager';
      this.isAllowed = StorageService.getUserRoles() !== 'FacilityManager';
      this.isAdvancedAdmin = StorageService.getAllUserRoles().includes('AdvancedAdmin');
      this.isJury = StorageService.getAllUserRoles().includes('Jury');
      this.isSanityCheckAdmin = StorageService.getAllUserRoles().includes('SanityCheckAdmin');
    }
  },

  watch: {
    $route( to, from ) {
      this.isActiveUsers = to.path
      this.computeBreadcrumb()
      this.isAuthenticated = AuthenticationService.isAuthenticated()
    },
  },
}
