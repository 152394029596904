export const USERS_LIST = 'users-list';
export const INSTANCES_LIST = 'instances-list';
export const USER_KEY = 'user-key';
export const USER_NAME = 'user-name';
export const INSTANCE_NAME = 'instance-name';
export const ACTIVE_INSTANCES_LIST = 'active-instances-list';
export const TOKEN_TYPE = 'token_type'
export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_ID = 'user_id'
export const USER_PHOTO = 'user_photo'
export const USER_ROLES = 'user_roles'
export const EMAIL = 'email'
export const LANGUAGE_KEY = 'language-key'
export const PREVIOUS_ROUTE = 'previous-route'

export const FACILITY_DATA = 'facility-data'
export const FACILITY_SPACE_DATA = 'facility-space-data'
export const ORGANIZATION_DATA = 'organization-data'
export const PRODUCT_DATA = 'product-data'
export const FAVOUR_DATA = 'favour-data'
export const EVENT_DATA = 'event-data'
export const BOOKING_DATA = 'booking-data'
export const TEXTS_DATA = 'texts-data'
export const NOTIFICATION_TEMPLATE_DATA = 'notification-template-data'
export const API_USER_ADDRESSES_DATA = 'api/user-addresses'
export const INSTANCE_HEALTH_DATA = 'api/instance/id/health'
export const ALL_ROLES = 'all-roles'


export default class StorageService {

  static setToStorage(key, value) {
    localStorage.setItem(key, value)
  }
  static getFromStorage(key) {
    return localStorage.getItem(key)
  }

  static setFacilityToStorage(facilityData) {
    localStorage.setItem(FACILITY_DATA, JSON.stringify(facilityData));
  }

  static setFacilitySpaceToStorage(facilitySpaceData) {
    localStorage.setItem(FACILITY_SPACE_DATA, JSON.stringify(facilitySpaceData));
  }

  static setOrganizationToStorage(organization) {
    localStorage.setItem(ORGANIZATION_DATA, JSON.stringify(organization));
  }

  static setProductToStorage(product) {
    localStorage.setItem(PRODUCT_DATA, JSON.stringify(product));
  }

  static setFavourToStorage(favour) {
    localStorage.setItem(FAVOUR_DATA, JSON.stringify(favour));
  }

  static setEventToStorage(favour) {
    localStorage.setItem(EVENT_DATA, JSON.stringify(favour));
  }

  static setBookingToStorage(favour) {
    localStorage.setItem(BOOKING_DATA, JSON.stringify(favour));
  }

  static setTextsToStorage(favour) {
    localStorage.setItem(TEXTS_DATA, JSON.stringify(favour));
  }

  static setNotificationTemplateToStorage(favour) {
    localStorage.setItem(NOTIFICATION_TEMPLATE_DATA, JSON.stringify(favour));
  }

  static getFacilityFromStorage() {
   return JSON.parse(localStorage.getItem(FACILITY_DATA));
  }

  static getEventToStorage() {
   return JSON.parse(localStorage.getItem(EVENT_DATA));
  }

  static getBookingToStorage() {
   return JSON.parse(localStorage.getItem(BOOKING_DATA));
  }

  static getTextsToStorage() {
   return JSON.parse(localStorage.getItem(TEXTS_DATA));
  }

  static getNotificationTemplateToStorage() {
   return JSON.parse(localStorage.getItem(NOTIFICATION_TEMPLATE_DATA));
  }

  static getFacilitySpaceFromStorage() {
   return JSON.parse(localStorage.getItem(FACILITY_SPACE_DATA));
  }

  static getOrganizationFromStorage() {
   return JSON.parse(localStorage.getItem(ORGANIZATION_DATA));
  }

  static getProductFromStorage() {
   return JSON.parse(localStorage.getItem(PRODUCT_DATA));
  }

  static getFavourFromStorage() {
   return JSON.parse(localStorage.getItem(FAVOUR_DATA));
  }

  static deleteFacilityFromStorage() {
   localStorage.removeItem(FACILITY_DATA);
  }

  static deleteEventToStorage() {
   localStorage.removeItem(EVENT_DATA);
  }

  static deleteBookingToStorage() {
   localStorage.removeItem(BOOKING_DATA);
  }

  static deleteTextsToStorage() {
   localStorage.removeItem(TEXTS_DATA);
  }

  static deleteNotificationTemplateToStorage() {
   localStorage.removeItem(NOTIFICATION_TEMPLATE_DATA);
  }

  static deleteFacilitySpaceFromStorage() {
   localStorage.removeItem(FACILITY_SPACE_DATA);
  }

  static deleteOrganizationFromStorage() {
   localStorage.removeItem(ORGANIZATION_DATA);
  }

  static deleteProductFromStorage() {
   localStorage.removeItem(PRODUCT_DATA);
  }

  static deleteFavourFromStorage() {
   localStorage.removeItem(FAVOUR_DATA);
  }

  static getUserRoles() {
    return localStorage.getItem(USER_ROLES);
  }

  static getAllUserRoles() {
    try {
      const allRoles = localStorage.getItem(ALL_ROLES);
      return allRoles ? JSON.parse(allRoles) : [];
    } catch (e) {
      return []
    }
  }

  static removeFromStorage(key) {
    localStorage.removeItem(key)
  }

  static clearAll() {
    localStorage.clear()
    sessionStorage.clear();
  }

  static saveUsers(data){
    sessionStorage.setItem(USERS_LIST, JSON.stringify(data));
  }

  static getUsers(){
    let data = sessionStorage.getItem(USERS_LIST);
    return data ? JSON.parse(data): [];
  }

  static setInstances(data){
    sessionStorage.setItem(INSTANCES_LIST, JSON.stringify(data));
  }

  static getInstances(){
    return sessionStorage.getItem(INSTANCES_LIST);
  }

  static removeInstances(){
    return sessionStorage.removeItem(INSTANCES_LIST);
  }

  static saveUserKey(data){
    sessionStorage.setItem(USER_KEY, JSON.stringify(data));
  }

  static getUserKey(){
    return sessionStorage.getItem(USER_KEY);
  }

  static saveUserName(data){
    sessionStorage.setItem(USER_NAME, data);
  }

  static getUserName(){
    return sessionStorage.getItem(USER_NAME);
  }

  static removeUserName(){
    return sessionStorage.removeItem(USER_NAME);
  }

  static saveInstanceName(data){
    sessionStorage.setItem(INSTANCE_NAME, data);
  }

  static getInstanceName(){
    return sessionStorage.getItem(INSTANCE_NAME);
  }

  static removeUsers(){
    sessionStorage.removeItem(USERS_LIST);
  }

  static removeUserAllData(){
    sessionStorage.removeItem(USERS_LIST);
    sessionStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(USER_NAME);
    sessionStorage.removeItem(INSTANCE_HEALTH_DATA);
    sessionStorage.removeItem(INSTANCES_LIST);
  }
}
