export const snippetList = [
  {
    name: 'Sanity Check',
    key: 'sanityCheck',
    value: 'Sanity Check',
  },
  {
    name: 'Candidate list',
    key: 'candidateList',
    value: 'Candidate list',
  },
  {
    name: 'Jury',
    key: 'jury',
    value: 'Jury',
  },
  {
    name: 'Winner announce',
    key: 'winnerAnnounce',
    value: 'Winner announce',
  },
];
