export const snippetList = [
  {
    name: 'Domains',
    key: 'domains',
    value: 'Domänen',
  },
  {
    name: 'AddDomain',
    key: 'add_domain',
    value: 'Domäne hinzufügen',
  },
  {
    name: 'Add Service',
    key: 'add_service',
    value: 'Add Service',
  },
  {
    name: 'Add Text',
    key: 'add_text',
    value: 'Add Text',
  },
  {
    name: 'Add Template',
    key: 'add_notificationTemplate',
    value: 'Add Template',
  },
  {
    name: 'Notification Template',
    key: 'notification_templates',
    value: 'Notification Template',
  },
  {
    name: 'Application Texts',
    key: 'texts',
    value: 'Application Texts',
  },
  {
    name: 'Notification Templates',
    key: 'notificationTemplates',
    value: 'Notification Templates',
  },
  {
    name: 'Edit Notification Template',
    key: 'edit_notificationTemplate',
    value: 'Edit Notification Template',
  },
  {
    name: 'Add Amenities',
    key: 'add_space',
    value: 'Add Amenities',
  },
  {
    name: 'email',
    key: 'email',
    value: 'email',
  },
  {
    name: 'Organization',
    key: 'organization',
    value: 'Organization',
  },
  {
    name: 'Roles',
    key: 'roles',
    value: 'Roles',
  },
  {
    name: 'Password',
    key: 'password',
    value: 'Password',
  },
  {
    name: 'Lock password',
    key: 'lock_password',
    value: 'Lock password',
  },
  {
    name: 'Cancel',
    key: 'cancel',
    value: 'Stornieren',
  },
  {
    name: 'Close',
    key: 'close',
    value: 'Close',
  },
  {
    name: 'Save',
    key: 'save',
    value: 'Speichern',
  },
  {
    name: 'Upload',
    key: 'upload',
    value: 'Upload',
  },
  {
    name: 'Approve pending membership',
    key: 'approve_membership',
    value: 'Approve pending membership',
  },
  {
    name: 'Download Report',
    key: 'download_report',
    value: 'Download Report',
  },
  {
    name: 'Clear',
    key: 'clear',
    value: 'Clear',
  },
  {
    name: 'Delete Confirm message',
    key: 'delete_confirm_message',
    value: 'Möchten Sie dieses Element wirklich löschen?',
  },
  {
    name: 'Facility Photos',
    key: 'showPhotos_message',
    value: 'Facility Photos',
  },
  {
    name: 'Delete',
    key: 'delete',
    value: 'Löschen',
  },
  {
    name: 'Upgrade',
    key: 'upgrade',
    value: 'Aktualisierung',
  },
  {
    name: 'Upgrade Confirm message',
    key: 'upgrade_confirm_message',
    value: 'Möchten Sie dieses Element wirklich aktualisieren?',
  },
  {
    name: 'Edit Domain',
    key: 'edit_domain',
    value: 'Domäne bearbeiten',
  },
  {
    name: 'Edit Event',
    key: 'edit_event',
    value: 'Edit Event',
  },
  {
    name: 'URL is not correct!',
    key: 'incorrect_url',
    value: 'URL ist nicht korrekt!',
  },
  {
    name: 'Key is required!',
    key: 'key_is_required',
    value: 'Schlüssel ist erforderlich!',
  },
  {
    name: 'Role is required',
    key: 'role_is_required',
    value: 'At least one role is required'
  },
  {
    name: 'Name is required!',
    key: 'name_is_required',
    value: 'Name ist erforderlich!',
  },
  {
    name: 'Description is required!',
    key: 'description_is_required',
    value: 'Description is required!'
  },
  {
    name: 'Short Description is required!',
    key: 'shortDescription_is_required',
    value: 'Short Description is required!'
  },
  {
    name: 'Map Position is required!',
    key: 'mapPosition_is_required',
    value: 'Map Position is required!'
  },
  {
    name: 'Can\'t be bookable and eventable at the same time!',
    key: 'cantBeBookableAndEventableAtTheSameTime',
    value: 'Can\'t be bookable and eventable at the same time!'
  },
  {
    name: 'QR code is required!',
    key: 'qrCode_is_required',
    value: 'QR code is required!'
  },
  {
    name: 'URL is required!',
    key: 'url_is_required',
    value: 'URL ist erforderlich!',
  },
  {
    name: 'URL is not valid!',
    key: 'url_is_not_valid',
    value: 'URL is not valid!',
  },
  {
    name: 'Type is required!',
    key: 'type_is_required',
    value: 'Typ ist erforderlich!',
  },
  {
    name: 'Billing Plan is required!',
    key: 'billing_plan_is_required',
    value: 'Abrechnungsplan ist erforderlich!',
  },
  {
    name: 'Email is required',
    key: 'email_is_required',
    value: 'Email is required',
  },
  {
    name: 'Pending',
    key: 'pending',
    value: 'Pending',
  },
  {
    name: 'Approved',
    key: 'approved',
    value: 'Approved',
  },
  {
    name: 'Membership status',
    key: 'membershipStatus',
    value: 'Membership status',
  },
  {
    name: 'First name is required',
    key: 'firstName_is_required',
    value: 'First name is required'
  },
  {
    name: 'Last name is required',
    key: 'lastName_is_required',
    value: 'Last name is required'
  },
  {
    name: 'Phone number is required',
    key: 'phoneNumber_is_required',
    value: 'Phone number is required'
  },
  {
    name: 'Password is required',
    key: 'password_is_required',
    value: 'Password is required'
  },
  {
    name: 'Created At',
    key: 'createDate',
    value: 'Hergestellt in',
  },
  {
    name: 'First Name',
    key: 'firstName',
    value: 'First Name',
  },
  {
    name: 'Name',
    key: 'name',
    value: 'Name',
  },
  {
    name: 'Full Address',
    key: 'fullAddress',
    value: 'Full Address',
  },
  {
    name: 'VAT Number',
    key: 'vatNumber',
    value: 'VAT Number',
  },
  {
    name: 'SDI Code',
    key: 'sdiCode',
    value: 'SDI Code',
  },
  {
    name: 'Credits For Events',
    key: 'creditsForEvents',
    value: 'Credits For Events',
  },
  {
    name: 'Credits For Bookings',
    key: 'creditsForBookings',
    value: 'Credits For Bookings',
  },
  {
    name: 'Floor Name',
    key: 'floorName',
    value: 'Floor Name',
  },
  {
    name: 'Amenities Name',
    key: 'spaceName',
    value: 'Amenities Name',
  },
  {
    name: 'Floor Description',
    key: 'floorDescription',
    value: 'Floor Description',
  },
  {
    name: 'Address',
    key: 'address',
    value: 'Address',
  },
  {
    name: 'Lock ID',
    key: 'lockId',
    value: 'Lock ID',
  },
  {
    name: 'Description',
    key: 'description',
    value: 'Description',
  },
  {
    name: 'Credits',
    key: 'credits',
    value: 'Credits',
  },
  {
    name: 'Event Half Day Credits',
    key: 'eventHalfDayCredit',
    value: 'Event Half Day Credits',
  },
  {
    name: 'Event Full Day Credits',
    key: 'eventFullDayCredit',
    value: 'Event Full Day Credits',
  },
  {
    name: 'Short Description',
    key: 'shortDescription',
    value: 'Short Description',
  },
  {
    name: 'Is Event Capable',
    key: 'isEventCapable',
    value: 'Is Event Capable',
  },
  {
    name: 'Is Invitational',
    key: 'isInvitational',
    value: 'Is Invitational',
  },
  {
    name: 'Is Bookable',
    key: 'isBookable',
    value: 'Is Bookable',
  },
  {
    name: 'Maximum Number Of Participants',
    key: 'maxNumberOfParticipants',
    value: 'Maximum Number Of Participants',
  },
  {
    name: 'QR Code',
    key: 'qrCode',
    value: 'QR Code',
  },
  {
    name: 'Map Position',
    key: 'mapPosition',
    value: 'Map Position',
  },
  {
    name: 'Floors',
    key: 'floors',
    value: 'Floors',
  },
  {
    name: 'Photos',
    key: 'photos',
    value: 'Photos',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    value: 'Last Name',
  },
  {
    name: 'Phone Number',
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    name: 'Updated At',
    key: 'updateDate',
    value: 'Aktualisiert am',
  },
  {
    name: 'URL',
    key: 'url',
    value: 'URL',
  },
  {
    name: 'Brand',
    key: 'brand',
    value: 'Brand',
  },
  {
    name: 'Type',
    key: 'type',
    value: 'Type',
  },
  {
    name: 'User Type',
    key: 'user_type',
    value: 'Kundentyp',
  },
  {
    name: 'Billing Plan Type',
    key: 'billing_plan_type',
    value: 'Abrechnungsplantyp',
  },
  {
    name: 'Add User',
    key: 'add_user',
    value: 'Kunde hinzufügen',
  },
  {
    name: 'Add Admin',
    key: 'add_admin',
    value: 'Add Admin',
  },
  {
    name: 'Add Manager',
    key: 'add_facility_manager',
    value: 'Add Manager',
  },
  {
    name: 'Add Jury',
    key: 'add_jury',
    value: 'Add jury',
  },
  {
    name: 'Add Advanced Admin',
    key: 'add_advancedAdmin',
    value: 'Add Advanced Admin',
  },
  {
    name: 'Add Product',
    key: 'add_product',
    value: 'Add Product',
  },
  {
    name: 'Add Facility',
    key: 'add_facility',
    value: 'Kunde Facility',
  },
  {
    name: 'Add Organization',
    key: 'add_organization',
    value: 'Add Organization',
  },
  {
    name: 'Edit Organization',
    key: 'edit_organization',
    value: 'Edit Organization',
  },
  {
    name: 'Add Photos',
    key: 'add_photos',
    value: 'Add Photos',
  },
  {
    name: 'Facility Details',
    key: 'facility_details',
    value: 'Facility Details',
  },
  {
    name: 'Facility Details',
    key: 'facility-details',
    value: 'Facility Details',
  },
  {
    name: 'Product Details',
    key: 'product_details',
    value: 'Product Details',
  },
  {
    name: 'Service Details',
    key: 'service_details',
    value: 'Service Details',
  },
  {
    name: 'Text Details',
    key: 'text_details',
    value: 'Text Details',
  },
  {
    name: 'Notification Templates Details',
    key: 'notificationTemplates_details',
    value: 'Notification Templates Details',
  },
  {
    name: 'Organization Details',
    key: 'organization_details',
    value: 'Organization Details',
  },
  {
    name: 'Amenities Details',
    key: 'space_details',
    value: 'Amenities Details',
  },
  {
    name: 'Work Days',
    key: 'work_days',
    value: 'Work Days',
  },
  {
    name: 'User Edit',
    key: 'user_edit',
    value: 'User Edit',
  },
  {
    name: 'Edit User',
    key: 'edit_user',
    value: 'Kunde bearbeiten',
  },
  {
    name: 'Edit Product',
    key: 'edit_product',
    value: 'Kunde Product',
  },
  {
    name: 'Edit Facility',
    key: 'edit_facility',
    value: 'Kunde Facility',
  },
  {
    name: 'Incorrect values!',
    key: 'required',
    value: 'Falsche Werte!',
  },
  {
    name: 'Name',
    key: 'name',
    value: 'Name',
  },
  {
    name: 'Users',
    key: 'users',
    value: 'Kunden',
  },
  {
    name: 'Admins',
    key: 'admins',
    value: 'Admins'
  },
  {
    name: 'Facility Managers',
    key: 'facility_managers',
    value: 'Facility Managers'
  },
  {
    name: 'Juries',
    key: 'juries',
    value: 'Juries'
  },
  {
    name: 'Advanced Admin',
    key: 'advanced_admin',
    value: 'Advanced Admin'
  },
  {
    name: 'Facilities',
    key: 'facilities',
    value: 'Facilities',
  },
  {
    name: 'Organizations',
    key: 'organizations',
    value: 'Organizations',
  },
  {
    name: 'Services',
    key: 'services',
    value: 'Services',
  },
  {
    name: 'Products',
    key: 'products',
    value: 'Products',
  },
  {
    name: 'Products',
    key: 'products',
    value: 'Products',
  },
  {
    name: 'Services',
    key: 'services',
    value: 'Services',
  },
  {
    name: 'Key',
    key: 'key',
    value: 'Taste',
  },
  {
    name: 'Title',
    key: 'title',
    value: 'Title',
  },
  {
    name: 'Title',
    key: 'language',
    value: 'Language',
  },
  {
    name: 'Text',
    key: 'text',
    value: 'Text',
  },
  {
    name: 'Language',
    key: 'language',
    value: 'Language',
  },
  {
    name: 'Id',
    key: 'id',
    value: 'Ausweis',
  },
  {
    name: 'Actions',
    key: 'actions',
    value: 'Aktionen',
  },
  {
    name: 'Domains List',
    key: 'domains_list',
    value: 'Domänenliste',
  },
  {
    name: 'The key has already been taken.',
    key: 'add_domain_error_message',
    value: 'Der Schlüssel wurde bereits genommen.',
  },
  {
    name: 'Search',
    key: 'search',
    value: 'Suche',
  },
];
